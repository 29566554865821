import { useState, useEffect, useRef } from "react";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
  createWeb3Modal,
  defaultConfig,
} from "@web3modal/ethers/react";

import moment from "moment";
import { getTransaction } from "../services/transaction";
import { Footer } from "../widgets/Footer";
import { Navbar } from "../widgets/Navbar";
import { useLocation } from "react-router-dom";
import { EthRpcUrl, bscRpcUrl, ethChainId } from "../../constant";
export const TransactionDetails = () => {
  const projectId = "4160d935fddfb1cfcb89bb947994839b";
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [currentPage, setCurrentPage] = useState(1);
  const [record, setRecord] = useState([]);
  const [totalUsdt, setTotalUsdt] = useState();
  const [totalToken, setTotalToken] = useState();
  const [bonus, setBonus] = useState("");
  const [referralBonus, setReferralBonus] = useState();
  const mainnet = [
    {
      chainId: 56,
      name: "Binance Smart Chain",
      currency: "BNB",
      explorerUrl: "https://bscscan.com",
      rpcUrl: bscRpcUrl,

      // chainId: binanceChainId,
      // name: "Binance Smart Chain Testnet",
      // currency: "tBNB",
      // explorerUrl: "https://testnet.bscscan.com",
      // rpcUrl: bscRpcUrl,
    },
    {
      // chainId: ethChainId,
      // name: "Ethereum Testnet",
      // currency: "ETH",
      // explorerUrl: "https://testnet.etherscan.io",
      // rpcUrl:
      //   EthRpcUrl,
      chainId: ethChainId,
      name: "Ethereum Mainnet",
      currency: "ETH",
      explorerUrl: "https://etherscan.io/",
      // rpcUrl: 'https://cloudflare-eth.com'
      rpcUrl: EthRpcUrl,
    },
  ];

  const bscRpc = bscRpcUrl;
  const EthRpc = EthRpcUrl;

  const metadata = {
    name: "crudo",
    description: "crudo",
    url: "https://crudoprotocol.com/",
    icons: ["https://crudoprotocol.com/html/images/crudo_Logo.svg"],
  };

  createWeb3Modal({
    ethersConfig: defaultConfig({ metadata }),
    chains: mainnet,
    projectId,
    enableAnalytics: true,
  });

  useEffect(() => {
    trnsactionGet();
  }, [address]);

  const homeRef = useRef(null);
  const tokenomicsRef = useRef(null);
  const faqRef = useRef(null);
  const roadmapRef = useRef(null);
  const aboutUsRef = useRef(null);
  const buynowRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    if (location.hash && homeRef.current) {
      const targetRef =
        location.hash === "#about-us"
          ? aboutUsRef
          : location.hash === "#tokenomics"
          ? tokenomicsRef
          : location.hash === "#faq"
          ? faqRef
          : location.hash === "#roadmap"
          ? roadmapRef
          : location.hash === "#buy-now"
          ? buynowRef
          : null;

      if (targetRef) {
        targetRef.current.scrollIntoView({ behavior: "auto", block: "start" });
      }
    }
  }, [location.hash]);

  const trnsactionGet = async () => {
    if (address) {
      //const addres = "0x4619FaFA4899a3aF655F3A8b887fDb450BeD8117"
      const addres = address;
      const resp = await getTransaction({ address:addres});

      if (resp.status) {
        setRecord(resp.data);
        setTotalUsdt(resp?.usdtAmount);
        setTotalToken(resp?.tokenAmt);
        const referralAmt = Number(resp?.referralAmount).toFixed(2);

        setReferralBonus(referralAmt);
        setBonus(resp?.bonus);
      }
    }
  };
  // ============== pagination===================///
  const itemsPerPage = 10;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = record.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };
  // ===================pagination=================//
  return (
    <>
      <div className="overflow-hidden bg1">
        <Navbar />

        <div id="scroll-to-top">
          <i className="bi bi-chevron-up fa-fw" />
        </div>

        <section className="crypto_page_bg ">
          <section className="ex_box p70 pn_page_pt ">
            <div className="container  position-relative">
            <w3m-button balance="hide" />
              <h2 className="hadding text-center">
                Transaction <span className="t_gr">Details</span>
              </h2>
              <div className="row text-center">
                <div class="col-md-6 mt-3">
                  <div class="box p-md-4">
                    <h5 className="mb-0">
                      Total Amount Purchased:{totalUsdt ? totalUsdt : 0} USD
                    </h5>
                  </div>
                </div>
                <div class="col-md-6 mt-3">
                  <div class="box p-md-4">
                    <h5 className="mb-0">
                      Total Crudo Tokens: {totalToken ? totalToken : 0}{" "}
                    </h5>
                  </div>
                </div>
              </div>
              <div className="row text-center">
                {bonus ? (
                  <div class="col-md-6 mt-3">
                    <div class="box p-md-4">
                      <h5 className="mb-0">
                        Bonus Token: {bonus ? bonus : 0}{" "}
                      </h5>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div class="col-md-6 mt-3">
                  <div class="box p-md-4">
                    <h5 className="mb-0">
                      Referral Bonus Token:{" "}
                      {referralBonus ? referralBonus : "0"}
                    </h5>
                  </div>
                </div>
              </div>
              {/* <div className="d-md-flex align-items-center ">
                    <span className="ttu fw600">Bonus Token: </span>
                    <span className="ml-auto h5">
                    {bonus ? bonus : "0"}
                    </span>
                  </div> */}
              <div className="row mt-md-4">
                <div class="col-md-12 mt-3">
                  <div class="UKC-middle-box box" id="buy-div">
                    <div class="select-currency-wrapper">
                      <div
                        class="wallet-code-disconnects mb-1 text-right"
                        id="conn-status"
                      ></div>
                      <div class="currency-select-wrap mb-1 table_scrool">
                        <div class="table-responsive">
                          <table
                            class="table table-striped "
                            style={{ color: "#fff" }}
                          >
                            <thead>
                              <tr>
                                <th>Sr No.</th>
                                <th>Chain</th>
                                <th>Currency</th>
                                <th>Amount</th>
                                <th>Token Amount</th>
                                <th>Status</th>
                                <th>Date Time</th>
                              </tr>
                            </thead>
                            <tbody id="table_body">
                              {currentItems &&
                                currentItems.map((rowData, index) => {
                                  const date = moment(
                                    rowData.created_at
                                  ).format("DD/MM/YYYY hh:mm A");

                                  return (
                                    <>
                                      <tr>
                                        <td>{index + 1}</td>
                                        <td>{rowData.chain}</td>
                                        <td>{rowData.currency}</td>
                                        <td>{rowData.amount}</td>
                                        <td>{rowData.token_amount}</td>
                                        <td>{rowData.status}</td>
                                        <td>{date}</td>
                                      </tr>
                                    </>
                                  );
                                })}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-right mt-4">
                    <ul className="pagination d-inline-flex">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(currentPage - 1)}
                        >
                          Previous
                        </button>
                      </li>
                      {Array.from({
                        length: Math.ceil(record.length / itemsPerPage),
                      }).map((_, index) => (
                        <li
                          key={index}
                          className={`page-item ${
                            currentPage === index + 1 ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => paginate(index + 1)}
                          >
                            {index + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage ===
                          Math.ceil(record.length / itemsPerPage)
                            ? "disabled"
                            : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => paginate(currentPage + 1)}
                        >
                          Next
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>

        <Footer />
      </div>
    </>
  );
};
